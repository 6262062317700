import { Box } from "@chakra-ui/react";
import React, { ReactNode } from "react";

const GradientBackground: React.FC<{
  theme?: string;
  children: ReactNode;
}> = ({ theme, children }) => {
  const isBackgroundImage = theme?.includes("image");
  const defaultBg = "black";
  return (
    <Box
      className={`bg-${theme ?? defaultBg}`}
      minH="100vh"
      w="100%"
      backgroundSize={isBackgroundImage ? "100%" : "auto"}
      backgroundRepeat={isBackgroundImage ? "repeat-y" : "repeat"}
      backgroundPosition={isBackgroundImage ? "center top" : "auto"}
    >
      {children}
    </Box>
  );
};

export default GradientBackground;
