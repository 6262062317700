import axios from "axios";
import { borderRadiusDisabledUsers } from "./constants";
import { ListItem, ListOption, UserList } from "./types/list";
import { Pin, PinOption } from "./types/pin";
import { ListDiff, SanitizedOtherUser, SimpleUser, User } from "./types/user";
import { errorToast } from "./utils";

export const getAuthHeader = (...headers: any) => {
  const token = localStorage.getItem("accessToken");
  if (!token) {
    return {};
  }
  return { headers: { ...headers, Authorization: `Bearer ${token}` } };
};

export const checkUser = async (
  value: string
): Promise<{
  success: boolean;
  username: boolean;
  email: boolean;
  phone: boolean;
  loginMethod?: string;
}> => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + "/auth/check-user?value=" + value
  );
  return response.data;
};

const requireAuthWithNav = async (
  navigate: (s: string) => void,
  fun: () => Promise<any>
) => {
  try {
    // Make API request with the valid access token
    const response = await fun();
    return response;
  } catch (err: any) {
    if (err.response?.status === 401) {
      if (err.response.data.message === "Token expired") {
        // refresh token
        const res = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/auth/refresh",
          {
            refreshToken: localStorage.getItem("refreshToken"),
          }
        );
        if (!res.data.accessToken) {
          // clear token
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          navigate("/login");
          throw err;
        }
        localStorage.setItem("accessToken", res.data.accessToken);
        return await fun();
      } else {
        // clear token
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        navigate("/login");
        return;
      }
    }
    if (process.env.REACT_APP_DEBUG) {
      console.error("Error making request");
      console.error(err);
    }
    throw err;
  }
};

export async function requireAuth<T>(fun: () => Promise<T>) {
  try {
    // Make API request with the valid access token
    const response = await fun();
    return response;
  } catch (err: any) {
    if (err.response?.status === 401) {
      if (err.response.data.message === "Token expired") {
        // refresh token
        const res = await axios.post(
          process.env.REACT_APP_SERVER_URL + "/auth/refresh",
          {
            refreshToken: localStorage.getItem("refreshToken"),
          }
        );
        if (res.data.accessToken) {
          localStorage.setItem("accessToken", res.data.accessToken);
          return await fun();
        }
      }
    }
    throw err;
  }
}

export const getMe = async (): Promise<User | null> => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_SERVER_URL + "/users/me",
      getAuthHeader()
    );
    const user = res.data;
    if (borderRadiusDisabledUsers.includes(user.username)) {
      localStorage.setItem("borderRadiusDisabled", "true");
    } else {
      localStorage.removeItem("borderRadiusDisabled");
    }
    return user;
  } catch (err: any) {
    if (err.response?.status === 401) {
      localStorage.removeItem("accessToken");
      if (err.response.data.message === "Token expired") {
        // refresh token
        try {
          const res = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/auth/refresh",
            {
              refreshToken: localStorage.getItem("refreshToken"),
            }
          );
          if (res.data.accessToken) {
            localStorage.setItem("accessToken", res.data.accessToken);
            return await getMe();
          }
        } catch (err: any) {
          if (process.env.REACT_APP_DEBUG) {
            console.error("Error refreshing token:\n", err);
          }
          localStorage.removeItem("refreshToken");
        }
      }
    } else {
      if (process.env.REACT_APP_DEBUG) {
        console.error("Error getting user:\n", err);
      }
    }
    // throw err;
    return null;
  }
};

export interface UpdateRequest {
  firstName?: string;
  lastName?: string;
  email?: string;
  username?: string;
  password?: string;
  profilePicture?: File;
  backgroundType?: string | null;
  listLayoutColumns?: number;
  hasOnboarded?: boolean;
}

export const updateMe = async ({
  firstName,
  lastName,
  email,
  username,
  password,
  profilePicture,
  backgroundType,
  listLayoutColumns,
  hasOnboarded,
}: UpdateRequest): Promise<User> => {
  const formData = new FormData();

  firstName && formData.append("firstName", firstName);
  lastName && formData.append("lastName", lastName);
  email && formData.append("email", email);
  username && formData.append("username", username);
  password && formData.append("password", password);
  backgroundType && formData.append("backgroundType", backgroundType);
  profilePicture && formData.append("profilePicture", profilePicture);
  listLayoutColumns &&
    formData.append("listLayoutColumns", listLayoutColumns.toString());
  hasOnboarded && formData.append("hasOnboarded", hasOnboarded.toString());

  const res = await axios.post(
    process.env.REACT_APP_SERVER_URL + "/users/me",
    formData,
    getAuthHeader({
      "Content-Type": "multipart/form-data",
    })
  );

  return res.data as User;
};

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  username: string;
  profilePicture?: File;
  email?: string;
  password?: string;
  phone?: string;
  firebaseToken?: string;
}

// Might throw error
export const signUp = async ({
  firstName,
  lastName,
  email,
  password,
  username,
  profilePicture,
  phone,
  firebaseToken,
}: SignUpRequest): Promise<User> => {
  const formData = new FormData();
  formData.append("firstName", firstName);
  formData.append("lastName", lastName);
  formData.append("username", username);
  if (email && password) {
    formData.append("email", email);
    formData.append("password", password);
  } else if (phone && firebaseToken) {
    formData.append("phone", phone);
    formData.append("firebaseToken", firebaseToken);
  } else {
    throw new Error("Invalid sign up request");
  }

  if (profilePicture) {
    formData.append("profilePicture", profilePicture);
  }

  const res = await axios.post(
    process.env.REACT_APP_SERVER_URL + "/auth/signup",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return res.data as User;
};

export interface WaitlistRequest {
  email?: string;
  phone?: string;
  firebaseToken?: string;
}

export const signUpWaitlist = async (data: WaitlistRequest): Promise<User> => {
  const res = await axios.post(
    process.env.REACT_APP_SERVER_URL + "/auth/signup-waitlist",
    data
  );

  return res.data as User;
};

export const getUser = async (
  username: string
): Promise<SanitizedOtherUser> => {
  const res = await axios.get(
    process.env.REACT_APP_SERVER_URL + "/users/" + username,
    getAuthHeader()
  );
  return res.data;
};

export const getAllUsers = async (
  searchTerm?: string
): Promise<SanitizedOtherUser[]> => {
  const res = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/users${
      !!searchTerm ? "?q=" + searchTerm : ""
    }`,
    getAuthHeader()
  );
  return res.data;
};

export const refreshIntegration = async (
  navigate: (s: string) => void,
  listSlug: string,
  integrationProviderSlug: string
): Promise<UserList | null> => {
  try {
    const res = await requireAuthWithNav(navigate, () =>
      axios.post(
        process.env.REACT_APP_SERVER_URL +
          `/users/me/lists/${listSlug}/refresh/${integrationProviderSlug}`,
        {},
        getAuthHeader()
      )
    );
    return res.data.list;
  } catch (err: any) {
    if (process.env.REACT_APP_DEBUG) {
      console.error("Error refreshing integration:\n", err);
    }
    return null;
  }
};

export const getListOptions = async (): Promise<{
  [key: string]: ListOption[];
}> => {
  const res = await requireAuth(() =>
    axios.get(process.env.REACT_APP_SERVER_URL + "/lists", getAuthHeader())
  );
  return res.data;
};

export const searchItems = async (
  listOptionID: string,
  query: string
): Promise<ListItem[]> => {
  if (!query) {
    return [];
  }
  const res = await axios.get(
    process.env.REACT_APP_SERVER_URL +
      `/lists/${listOptionID}/search?query=${query}`,
    getAuthHeader()
  );
  return res.data;
};

interface PodiumsResponse {
  success: boolean;
  message?: string;
  list?: UserList;
}

export const sendAccessToken = async (
  payload: any,
  providerSlug: string,
  navigate: (s: string) => void,
  toast: any
): Promise<PodiumsResponse> => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_SERVER_URL +
        "/callbacks/" +
        providerSlug +
        "/set-user-token",
      payload,
      getAuthHeader()
    );
    if (res.data.success) {
      return res.data;
    } else {
      return { success: false, message: res.data.error };
    }
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG) {
      console.error("Error sending access token:", error.response);
    }
    errorToast(error.response?.data?.error, toast);
    if (error.response?.status === 401) {
      console.log("logged out");
      navigate("/login");
    }
    return { success: false };
  }
};

export const verifyEmail = async (
  nonce?: string
): Promise<{
  success: boolean;
  message: string;
  emailVerificationSent?: string;
}> => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_SERVER_URL + "/auth/verify-email",
      {
        nonce,
      },
      getAuthHeader()
    );
    return res.data;
  } catch (err: any) {
    if (process.env.REACT_APP_DEBUG) {
      console.error("Error verifying email:\n", err);
    }
    return err.response.data;
  }
};

export const passwordReset = async (args: {
  nonce?: string;
  value?: string;
  newPassword?: string;
}): Promise<{
  success: boolean;
  message: string;
  passwordResetSent?: string;
}> => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_SERVER_URL + "/auth/reset-password",
      args
    );
    return res.data;
  } catch (err: any) {
    if (process.env.REACT_APP_DEBUG) {
      console.error("Error resetting password:\n", err);
    }
    return err.response.data;
  }
};

export const getRecommendations = async (
  listSlug: string
): Promise<{
  allData: boolean;
  categories: [
    {
      name: string;
      items: ListItem[];
    }
  ];
}> => {
  const res = await requireAuth(async () =>
    axios.get(
      process.env.REACT_APP_SERVER_URL +
        `/users/me/lists/${listSlug}/recommended`,
      getAuthHeader()
    )
  );
  return res.data;
};

export const getDiffs = async (): Promise<ListDiff[]> => {
  const res = await requireAuth(async () =>
    axios.get(
      process.env.REACT_APP_SERVER_URL + "/diffs?limit=30",
      getAuthHeader()
    )
  );
  return res?.data?.diffs ?? [];
};

export const deleteViews = async (): Promise<{
  success: boolean;
  error?: any;
}> => {
  const res = await axios.delete(
    process.env.REACT_APP_SERVER_URL + "/migrations/views",
    getAuthHeader()
  );

  return res.data;
};

export const reorderLists = async (reorder: {
  singleColListIDs?: string[];
  leftColListIDs?: string[];
  rightColListIDs?: string[];
}): Promise<User> => {
  const res = await axios.post(
    process.env.REACT_APP_SERVER_URL + "/users/me/lists",
    reorder,
    getAuthHeader()
  );

  return res.data;
};

export const disconnectSpotify = async (): Promise<User> => {
  const res = await axios.delete(
    process.env.REACT_APP_SERVER_URL + "/callbacks/spotify",
    getAuthHeader()
  );

  return res.data;
};

export const followUser = async (
  username: string
): Promise<{ success: boolean; error?: string; followedID?: string }> => {
  const res = await requireAuth(async () =>
    axios.post(
      process.env.REACT_APP_SERVER_URL + "/users/" + username + "/follow",
      {},
      getAuthHeader()
    )
  );

  return res.data;
};

export const unfollowUser = async (
  username: string
): Promise<{ success: boolean; error?: string; unfollowedID?: string }> => {
  const res = await requireAuth(async () =>
    axios.post(
      process.env.REACT_APP_SERVER_URL + "/users/" + username + "/unfollow",
      {},
      getAuthHeader()
    )
  );

  return res.data;
};

export const getMyFollowers = async (): Promise<SimpleUser[]> => {
  const res = await requireAuth(async () =>
    axios.get(
      process.env.REACT_APP_SERVER_URL + "/users/me/followers",
      getAuthHeader()
    )
  );
  return res.data;
};

export const getMyFollowing = async (): Promise<SimpleUser[]> => {
  const res = await requireAuth(async () =>
    axios.get(
      process.env.REACT_APP_SERVER_URL + "/users/me/following",
      getAuthHeader()
    )
  );
  return res.data;
};

export const getMyFeed = async (): Promise<ListDiff[]> => {
  const res = await requireAuth(async () =>
    axios.get(
      process.env.REACT_APP_SERVER_URL + "/diffs/following",
      getAuthHeader()
    )
  );
  return res.data;
};

export const getPinOptions = async (): Promise<{
  [key: string]: PinOption;
}> => {
  const res = await requireAuth(() =>
    axios.get(process.env.REACT_APP_SERVER_URL + "/pins", getAuthHeader())
  );
  return res.data;
};

export const createPin = async (pin: Pin): Promise<Pin[]> =>
  requireAuth(async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/users/me/pins/${pin.slug}`,
      pin,
      getAuthHeader()
    );
    return res.data;
  });

export const deletePin = async (pinSlug: string): Promise<Pin[]> =>
  requireAuth(async () => {
    const res = await axios.delete(
      `${process.env.REACT_APP_SERVER_URL}/users/me/pins/${pinSlug}`,
      getAuthHeader()
    );
    return res.data;
  });

export const getWaitlist = async (): Promise<User[]> => {
  const res = await requireAuth(async () =>
    axios.get(
      process.env.REACT_APP_SERVER_URL + "/users/waitlist",
      getAuthHeader()
    )
  );
  return res.data;
};
