import {
  Box,
  Button,
  Center,
  Circle,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { FaCompactDisc, FaMusic, FaSpotify, FaUsers } from "react-icons/fa6";
import { TbShare2 } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { Header } from "src/components/Header/Header";
import PodiumsCard from "src/components/PodiumsCard";
import GradientBackground from "src/components/User/UserBackground";
import { useUpdateMe } from "src/state/userQueries";
interface Step {
  title: string;
  description?: string;
  element: React.ReactNode;
  optional?: boolean;
}
const steps: Step[] = [
  {
    title: "",
    element: (
      <Center>
        <PodiumsCard
          boxShadow="none"
          as={motion.div}
          layoutId="main-card"
          bg="none"
        >
          <VStack spacing={0}>
            <Heading fontSize="24px" fontWeight={500} color="gray.400">
              Welcome to
            </Heading>
            <Heading fontSize="60px" color="white">
              Podiums
            </Heading>
            <Heading size="lg" textAlign="center" mt="8px" color="white">
              Let's get started.
            </Heading>
          </VStack>
        </PodiumsCard>
      </Center>
    ),
  },
  {
    title: "Create lists",
    description:
      "From your current songs, to your favorite movies, to your sports teams.",
    element: (
      <PodiumsCard
        as={motion.div}
        layoutId="main-card"
        w="180px"
        mt="20px"
        boxShadow="lg"
      >
        <VStack spacing={2} align="stretch" w="100%">
          {[...Array(5)].map((_, index) => (
            <HStack>
              <Box bg="gray.200" w="40px" h="40px" borderRadius="md" />
              <VStack align="start" spacing={1}>
                <Box bg="gray.200" w="60px" h="10px" borderRadius="md" />
                <Box bg="gray.200" w="95px" h="10px" borderRadius="md" />
              </VStack>
            </HStack>
          ))}
        </VStack>
      </PodiumsCard>
    ),
  },
  {
    title: "Connect your Spotify",
    optional: true,
    description:
      "Import your top-listened songs, artists, and albums to quickly create your first few lists.",
    element: (
      <VStack>
        <PodiumsCard
          w="180px"
          as={motion.div}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
          boxShadow="lg"
        >
          <VStack color="gray.500">
            <Icon as={FaSpotify} boxSize="34px" />
            <HStack>
              <Icon as={FaMusic} />
              <Icon as={FaCompactDisc} />
              <Icon as={FaUsers} />
            </HStack>
          </VStack>
        </PodiumsCard>
        <PodiumsCard
          as={motion.div}
          layoutId="main-card"
          boxShadow="lg"
          w="180px"
        >
          <VStack spacing={2} align="stretch" w="100%">
            {[...Array(5)].map((_, index) => (
              <HStack key={index}>
                <Box
                  bg="gray.200"
                  w="40px"
                  h="40px"
                  borderRadius="md"
                  as={motion.div}
                  layoutId={`image-${index}`}
                />
                <VStack align="start" spacing={1}>
                  <Box bg="gray.200" w="60px" h="10px" borderRadius="md" />
                  <Box bg="gray.200" w="95px" h="10px" borderRadius="md" />
                </VStack>
              </HStack>
            ))}
          </VStack>
        </PodiumsCard>
      </VStack>
    ),
  },
  {
    title: "Personalize your profile",
    description:
      "Choose a new background and layout to stand out from the crowd.",
    element: (
      <VStack
        as={motion.div}
        layoutId="purple-bg"
        w="100%"
        pt="30px"
        className="bg-purple-gradient"
        style={{
          maskImage: "linear-gradient(to bottom, transparent 0%, black 20%)",
        }}
        spacing={2}
      >
        <PodiumsCard
          w="180px"
          as={motion.div}
          layoutId="profile-card"
          boxShadow="lg"
          align="start"
          py="10px"
        >
          <HStack>
            <Circle size="34px" bg="gray.200"></Circle>
            <VStack align="start" spacing={1}>
              <Box bg="gray.200" w="95px" h="10px" borderRadius="md" />
              <Box bg="gray.200" w="60px" h="10px" borderRadius="md" />
            </VStack>
          </HStack>
        </PodiumsCard>
        {[...Array(3)].map((_, index) => (
          <PodiumsCard
            as={index === 0 ? motion.div : undefined}
            layoutId={index === 0 ? "main-card" : undefined}
            w="180px"
            boxShadow="lg"
            p="8px"
          >
            <VStack align="start" spacing="5px" w="100%">
              <Box bg="gray.200" w="50px" h="10px" borderRadius="md" />
              <Grid w="100%" gridTemplateColumns="repeat(5, 1fr)" gap="5px">
                {[...Array(5)].map((_, itemIndex) => (
                  <GridItem
                    key={itemIndex}
                    bg="gray.200"
                    w="100%"
                    h="30px"
                    borderRadius="md"
                    as={index === 0 ? motion.div : undefined}
                    layoutId={index === 0 ? `image-${itemIndex}` : undefined}
                  />
                ))}
              </Grid>
            </VStack>
          </PodiumsCard>
        ))}
      </VStack>
    ),
  },
  {
    title: "Share your personal link",
    description:
      "Whether it's TikTok, Instagram, or Tinder, show your friends who you really are.",
    element: (
      <Center
        as={motion.div}
        layoutId="purple-bg"
        w="100%"
        className="bg-purple-gradient"
        style={{
          maskImage: "linear-gradient(to bottom, transparent 0%, black 20%)",
        }}
        pt="50px"
        alignItems="start"
      >
        <Box
          as={motion.div}
          layoutId="profile-card"
          bg="white"
          w="280px"
          h="60px"
          borderRadius="full"
          display="flex"
          alignItems="center"
          justifyContent="center"
          pos="relative"
          mt="70px"
        >
          <HStack spacing="2px">
            <Text fontSize="20px" fontWeight="bold" color="gray.300">
              podiu.ms/
            </Text>
            <Text fontSize="20px" fontWeight="bold">
              you
            </Text>
          </HStack>
          <Icon
            as={TbShare2}
            boxSize="26px"
            color="gray.300"
            pos="absolute"
            right="12px"
            top="16px"
          />
        </Box>
      </Center>
    ),
  },
];

const OnboardingPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);

  const updateMe = useUpdateMe();

  const nextStep = async () => {
    if (currentStep === steps.length - 1) {
      setLoading(true);
      await updateMe.mutateAsync({ hasOnboarded: true });
      setLoading(false);
      navigate("/create/list");
      // Finish
    }
    setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
  };
  const prevStep = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

  return (
    <GradientBackground theme="black">
      <Header logoColor="white" />
      <Center width="100%" position="relative" h="70vh">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          maxW="450px"
          w="100%"
        >
          <VStack spacing={6} align="stretch" w="100%">
            <AnimatePresence mode="popLayout">
              <motion.div
                key={currentStep}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.5 }}
              >
                <VStack spacing={4} align="stretch">
                  <Box
                    h="300px"
                    mb="-40px"
                    style={{
                      maskImage:
                        "linear-gradient(to bottom, black 60%, transparent 95%)",
                    }}
                    display="flex"
                    justifyContent="center"
                  >
                    {steps[currentStep].element}
                  </Box>
                </VStack>
              </motion.div>
            </AnimatePresence>

            {currentStep !== 0 && (
              <HStack justifyContent="center" mt={4}>
                {steps.slice(1).map((_, index) => (
                  <Box
                    key={index}
                    w={2}
                    h={2}
                    borderRadius="full"
                    bg={index + 1 === currentStep ? "blue.500" : "gray.200"}
                  />
                ))}
              </HStack>
            )}

            <Heading size="lg" textAlign="center" color="white">
              {steps[currentStep].title}
            </Heading>
            {steps[currentStep].optional && (
              <Heading
                size="20px"
                textAlign="center"
                mt="-20px"
                fontWeight={500}
                color="gray.500"
              >
                (optional)
              </Heading>
            )}
            <Center w="100%" px={3} display="flex" flexDir="column" gap={3}>
              {steps[currentStep].description && (
                <Text textAlign="center" color="gray.300" w="90%">
                  {steps[currentStep].description}
                </Text>
              )}

              <AnimatePresence mode="wait">
                <HStack
                  justifyContent={currentStep > 0 ? "space-between" : "center"}
                  w="100%"
                  as={motion.div}
                  layoutId="buttons"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  mt="1em"
                >
                  {currentStep > 0 && (
                    <Button
                      onClick={prevStep}
                      isDisabled={currentStep === 0}
                      bg="none"
                      color="white"
                      cursor="pointer"
                      as={motion.div}
                      _hover={{ bg: "none", opacity: 0.8 }}
                    >
                      Previous
                    </Button>
                  )}
                  <Button
                    onClick={nextStep}
                    variant="dark"
                    isLoading={loading}
                    size={currentStep === 0 ? "lg" : "md"}
                  >
                    {currentStep === steps.length - 1
                      ? "Create a List"
                      : "Continue"}
                  </Button>
                </HStack>
              </AnimatePresence>
            </Center>
          </VStack>
        </Box>
      </Center>
    </GradientBackground>
  );
};

export default OnboardingPage;
