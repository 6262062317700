import { Card, HStack, Image, Link } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaBolt, FaMagnifyingGlass } from "react-icons/fa6";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  BACKDROP_BLUR,
  BACKGROUND_DARK_GRADIENT_COLORED,
  blackAfter,
  blackBefore,
} from "src/constants";
import { useCurrentUser } from "src/state/userQueries";
import { getTheme } from "src/types/userThemes";
import { ActionButton } from "../Buttons";
import { HomePageHeader } from "./HomePageHeader";
import { ProfileButton } from "./ProfileButton";
import { ShareButton } from "./ShareButton";

const PRIVATE_ROUTES = ["/me", "/me/activity", "/create/list", "/create/pin"];

export const Logo: React.FC<{
  height?: string;
  width?: string;
  linkDisabled?: boolean;
  color?: string;
}> = ({ height, width, linkDisabled, color }) => {
  const InnerLogo = () => {
    return (
      <Image
        src={`/podiums-logo${
          !!color ? (color.includes("-faded") ? "-white" : `-${color}`) : ""
        }.png`}
        opacity={!!color && color.includes("-faded") ? 0.85 : 1}
        h={height}
        w={width}
      />
    );
  };
  return linkDisabled ? (
    <InnerLogo />
  ) : (
    <Link as={RouterLink} to="/">
      <InnerLogo />
    </Link>
  );
};

export const AppIconLogo: React.FC<{
  height?: string;
  width?: string;
  linkDisabled?: boolean;
  color?: string;
}> = ({ height, width, linkDisabled, color }) => {
  return true ? (
    <Logo height="100" color="original" />
  ) : (
    <Card
      boxSize="100px"
      justifyContent="center"
      alignItems="center"
      display="flex"
      borderRadius="15px"
      background={BACKGROUND_DARK_GRADIENT_COLORED}
      backgroundClip="border-box"
      _before={blackBefore}
      _after={blackAfter}
    >
      <Logo height="55px" color={color} />
    </Card>
  );
};

// const CreateListButton = () => {
//   return (
//     <Link as={RouterLink} to="/create/list">
//       <Icon
//         as={FaPlus}
//         aria-label="Create List"
//         size="2em"
//         fontSize="24px"
//         mb="-4px"
//         ml="6px"
//         mr="-4px"
//         _hover={{ cursor: "pointer" }}
//       />
//     </Link>
//   );
// };

export const ActivityButton = () => {
  return (
    <ActionButton icon={FaBolt} bg="white" as={RouterLink} to="/activity" />
  );
};

export const ExploreButton = () => {
  return (
    <ActionButton
      icon={FaMagnifyingGlass}
      bg="white"
      as={RouterLink}
      to="/explore"
    />
  );
};

export const Header: React.FC<{
  hideSearch?: boolean;
  logoOnly?: boolean;
  logoColor?: string;
}> = ({ hideSearch, logoOnly, logoColor }) => {
  const navigate = useNavigate();
  const { data: user, isLoading } = useCurrentUser();
  const location = useLocation();

  useEffect(() => {
    if (isLoading) return;
    const accessingPrivateRoutesOrProfile = [
      ...PRIVATE_ROUTES,
      `/${user?.username}`,
      `/${user?.username}/`,
    ].includes(location.pathname);

    if (
      // logged out and trying to access private routes
      (!user && PRIVATE_ROUTES.includes(location.pathname)) ||
      // logged in but user has not set their username and trying to access private routes or their profile
      (!!user &&
        user.username.startsWith("!not-set-") &&
        accessingPrivateRoutesOrProfile)
    ) {
      navigate("/signup");
    } else if (!user?.hasOnboarded && accessingPrivateRoutesOrProfile) {
      navigate("/onboarding");
    }
    // else if (user.email && !user.loginMethod && !user.emailVerified) {
    // Go to verify email page
    // if (location.pathname !== "/verify-email" && location.pathname !== "/") {
    //   console.log("navigating to /verify-email");
    //   navigate("/verify-email");
    // }
    // }
  }, [user, isLoading, location.pathname, navigate]);

  if (logoOnly) {
    return (
      <HStack px={3} py="1em" w="100%" justifyContent="center">
        <Logo height="40px" color={logoColor} />
      </HStack>
    );
  } else if (
    location.pathname === "/" ||
    !user ||
    isLoading ||
    user.username.startsWith("!not-set-")
    //  || (user.email && !user.loginMethod && !user.emailVerified)
  ) {
    return <HomePageHeader logoColor={logoColor} />;
  } else {
    const theme = getTheme(user.backgroundType);
    return (
      <HStack px={3} py="0.75em" justify="space-between" alignItems="center">
        <HStack>
          <Logo height="40px" color={logoColor ?? theme.logoColor ?? "white"} />
        </HStack>
        <HStack
          alignItems="center"
          bg={logoColor ?? theme.cardBg}
          backdropFilter="auto"
          backdropBlur={BACKDROP_BLUR}
          borderRadius="full"
          p="4px"
          spacing="6px"
          boxShadow={theme.cardShadow}
          borderWidth={theme.cardBorderColor !== "none" ? "1px" : 0}
          borderColor={theme.cardBorderColor}
        >
          <ShareButton username={user.username} />
          <ProfileButton user={user} />
        </HStack>
      </HStack>
    );
  }
};
