import { Button, Center, Icon, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { BACKGROUND_FADED } from "src/constants";

export const FloatingCreateButton: React.FC<{
  isEditingLists: boolean;
  onSaveLists: () => void;
}> = ({ isEditingLists, onSaveLists }) => {
  const navigate = useNavigate();
  return isEditingLists ? (
    <Center zIndex={3} pos="fixed" bottom="20px" right="20px" left="20px">
      <motion.div layoutId="create-save-button">
        <Button
          flex={1}
          onClick={onSaveLists}
          display="flex"
          flexDir="row"
          justifyContent="center"
          alignItems="center"
          aria-label="Save lists"
          gap={3}
          height="60px"
          px={2}
          mt="2px"
          bg={BACKGROUND_FADED}
          backdropFilter="auto"
          backdropBlur="8px"
          boxShadow="0px 0px 15px rgba(0, 0, 0, 0.3)"
          borderRadius="full"
          w="200px"
        >
          <Text fontSize="18px" fontWeight={500}>
            Save
          </Text>
          <Icon as={FaCheckCircle} fontSize="24px" />
        </Button>
      </motion.div>
    </Center>
  ) : (
    <motion.div layoutId="create-save-button">
      <Button
        flex={1}
        display="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        aria-label="Create list"
        bg={BACKGROUND_FADED}
        backdropFilter="auto"
        backdropBlur="8px"
        boxShadow="0px 0px 15px rgba(0, 0, 0, 0.3)"
        borderRadius="full"
        width="60px"
        gap={0}
        height="60px"
        px={2}
        position="fixed"
        bottom="20px"
        right="20px"
        onClick={() => {
          navigate("/create/list");
        }}
        cursor="pointer"
      >
        <Icon as={FaPlus} fontSize="28px" />
      </Button>
    </motion.div>
  );
};
