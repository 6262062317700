import { Center, Heading, useDisclosure, useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useFeatureFlagEnabled } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import { FaCircleXmark } from "react-icons/fa6";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Header } from "src/components/Header/Header";
import ListOfLists from "src/components/Lists/View/ListOfLists";
import { ShimmerUser } from "src/components/Shimmer";
import GradientBackground from "src/components/User/UserBackground";
import { UserProfileHeader } from "src/components/User/UserProfileHeader";
import {
  CURRENT_USER_QUERY,
  useCurrentUser,
  useDisconnectSpotify,
  useGetUser,
  useUpdateMe,
} from "src/state/userQueries";
import "../backgrounds.css";
import { UserPins } from "../components/Pins/Pins";
import PodiumsCard from "../components/PodiumsCard";
import { PodiumsDialog } from "../components/PodiumsDialog";

const UserPage: React.FC = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const disconnectSpotifyDisclosure = useDisclosure();
  const editListsDisclosure = useDisclosure();
  const [isSingleCol, setIsSingleCol] = useState<boolean | undefined>(
    undefined
  );
  const toast = useToast();

  const pinsEnabled = useFeatureFlagEnabled("pinsEnabled");

  const {
    data: currentUser,
    isLoading: curUserLoading,
    isError: curUserError,
  } = useCurrentUser();
  const onMyProfile =
    curUserLoading || (!!currentUser && currentUser.username === username);
  const {
    data: getUserData,
    isError: getUserError,
    isLoading: getUserLoading,
  } = useGetUser(username ?? "", !onMyProfile);

  const disconnectSpotify = useDisconnectSpotify();
  const location = useLocation();
  const updateMe = useUpdateMe();
  const backURL = location.state?.backURL;
  const queryClient = useQueryClient();

  const user = onMyProfile ? currentUser! : getUserData;
  const [theme, setTheme] = useState<string | undefined>(user?.backgroundType);

  useEffect(() => {
    if (!user) return;
    setTheme(user?.backgroundType);
    setIsSingleCol(user?.listLayout.length === 1);
  }, [user]);

  const onDisconnectSpotify = () => {
    toast.promise(disconnectSpotify.mutateAsync(), {
      success: { title: "Spotify disconnected" },
      loading: { title: "Loading..." },
      error(err) {
        return {
          title: "Error",
          description: err.message,
        };
      },
    });
    navigate("/me");
  };

  useEffect(() => {
    if (username !== username?.toLowerCase()) {
      navigate(`/${username?.toLowerCase()}`);
      return;
    }

    document.title = "Podiums" + (!!user && `— @${user.username}`);
  }, [username, user, navigate, curUserError, getUserError]);

  const onSaveTheme = () => {
    if (
      theme !== user?.backgroundType ||
      isSingleCol !== (user?.listLayout.length === 1)
    ) {
      toast.promise(
        updateMe.mutateAsync({
          backgroundType: theme,
          listLayoutColumns: isSingleCol ? 1 : 2,
        }),
        {
          success: { title: "Theme saved" },
          loading: { title: "Loading..." },
          error(err) {
            return {
              title: "Error",
              description: err.message,
            };
          },
        }
      );
      queryClient.invalidateQueries({ queryKey: CURRENT_USER_QUERY });
      navigate(`/me`);
    }
  };

  if (getUserLoading || curUserLoading) {
    return <ShimmerUser />;
  } else if (getUserError || !user) {
    return (
      <>
        <GradientBackground>
          <Header />
          <Center width="100%" px={3}>
            <PodiumsCard>
              <Heading size="4xl">😥</Heading>
              <Heading>User not found</Heading>
            </PodiumsCard>
          </Center>
        </GradientBackground>
      </>
    );
  } else {
    const hasSpotify =
      !!onMyProfile && currentUser
        ? currentUser.oauthIntegrations.find(
            (i) => i.providerSlug === "spotify"
          )
        : false;
    return (
      <>
        <GradientBackground theme={theme}>
          <Header />
          <UserProfileHeader
            user={user}
            isLoggedIn={!!currentUser}
            onMyProfile={onMyProfile}
            onEditLists={editListsDisclosure.onOpen}
            // onReorderLists={reorderListsDisclosure.onOpen}
            // isReorderingLists={reorderListsDisclosure.isOpen}
            onDisconnectSpotify={
              hasSpotify ? disconnectSpotifyDisclosure.onOpen : undefined
            }
            updateThemeLocal={setTheme}
            updateLayoutLocal={setIsSingleCol}
            onSaveTheme={onSaveTheme}
            backURL={backURL}
          />
          {pinsEnabled && (
            <UserPins
              items={user?.pins ?? []}
              onMyProfile={onMyProfile}
              username={username ?? ""}
            />
          )}
          <ListOfLists
            user={user}
            onMyProfile={onMyProfile}
            localIsSingleColumn={isSingleCol}
            editListsDisclosure={editListsDisclosure}
          />
        </GradientBackground>
        <PodiumsDialog
          disclosure={disconnectSpotifyDisclosure}
          title="Disconnect Spotify"
          message="Are you sure you want to disconnect Spotify? This will remove your recommendations on Podiums, and stop auto-syncing any connected lists."
          buttons={[
            {
              text: "Disconnect",
              onClick: onDisconnectSpotify,
              colorScheme: "red",
              icon: FaCircleXmark,
            },
          ]}
          cancelText="Cancel"
        />
      </>
    );
  }
};

export default UserPage;
