import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useEffect } from "react";
import {
  SignUpRequest,
  UpdateRequest,
  WaitlistRequest,
  createPin,
  deletePin,
  disconnectSpotify,
  followUser,
  getAllUsers,
  getAuthHeader,
  getMe,
  getMyFollowers,
  getMyFollowing,
  getUser,
  getWaitlist,
  reorderLists,
  requireAuth,
  signUp,
  signUpWaitlist,
  unfollowUser,
  updateMe,
} from "src/networking";
import { UserList } from "src/types/list";
import { Pin } from "src/types/pin";
import { User } from "src/types/user";
import { ListDiff } from "../types/user";

export const users = createQueryKeys("users", {
  me: {
    queryKey: null,
    queryFn: () => {
      if (localStorage.getItem("accessToken")) {
        return getMe();
      }
      return null;
    },
  },
  myFollowers: {
    queryKey: null,
    queryFn: getMyFollowers,
  },
  myFollowing: {
    queryKey: null,
    queryFn: getMyFollowing,
  },
  all: (query?: string) => ({
    queryKey: [query],
    queryFn: () => getAllUsers(query),
  }),
  byUsername: (username: string) => ({
    queryKey: [username],
    queryFn: () => getUser(username),
  }),
  waitlist: {
    queryKey: null,
    queryFn: getWaitlist,
  },
});

export const CURRENT_USER_QUERY = users.me.queryKey;

export const useCurrentUser = () => {
  return useQuery({
    ...users.me,
    staleTime: 1000 * 60 * 5,
    retry: false,
    // retryDelay: 1000 * 60 * 5,
    // retry: 1,
    select: (data) => (!!data ? populateListColumns(data) : null),
  });
};

export const useMyFollowers = () => {
  return useQuery({
    ...users.myFollowers,
    staleTime: 1000 * 60 * 5,
  });
};

export const useMyFollowing = () => {
  return useQuery({
    ...users.myFollowing,
    staleTime: 1000 * 60 * 5,
  });
};

export const useGetUser = (username: string, enabled: boolean) => {
  return useQuery({
    ...users.byUsername(username),
    staleTime: 1000 * 60 * 5,
    retry: false,
    enabled: enabled,
    select: populateListColumns,
  });
};

function populateListColumns<
  T extends {
    lists: UserList[];
    listLayout: string[][];
  }
>(user: T) {
  if (user.listLayout.length === 1) {
    // set lists and sort by listLayout array containing _ids
    return {
      ...user,
      lists: (user.lists ?? []).sort((a, b) => {
        const aIndex = user.listLayout[0].indexOf(a._id ?? "");
        const bIndex = user.listLayout[0].indexOf(b._id ?? "");
        return aIndex - bIndex;
      }),
      leftColLists: user.lists.slice(0, Math.ceil(user.lists?.length / 2)),
      rightColLists: user.lists.slice(Math.ceil(user.lists?.length / 2)),
    };
  } else {
    const leftCol = user.lists
      .filter((l) => user.listLayout[0].includes(l._id ?? ""))
      .sort((a, b) => {
        const aIndex = user.listLayout[0].indexOf(a._id ?? "");
        const bIndex = user.listLayout[0].indexOf(b._id ?? "");
        return aIndex - bIndex;
      });
    return {
      ...user,
      lists: user.lists ?? [],
      leftColLists: leftCol,
      rightColLists: user.lists
        .filter((l) => leftCol.map((l) => l._id).indexOf(l._id) === -1)
        .sort((a, b) => {
          const aIndex = user.listLayout[1].indexOf(a._id ?? "");
          const bIndex = user.listLayout[1].indexOf(b._id ?? "");
          return aIndex - bIndex;
        }),
    };
  }
};

export const useGetAllUsers = (searchTerm?: string) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    ...users.all(searchTerm),
    staleTime: 1000 * 30, // 30 seconds
    retry: false,
  });

  useEffect(() => {
    if (query.data) {
      query.data.forEach((user) => {
        queryClient.setQueryData(
          users.byUsername(user.username).queryKey,
          user
        );
      });
    }
  }, [query.data, queryClient]);

  return query;
};

export const useGetWaitlist = () => {
  return useQuery({
    ...users.waitlist,
    staleTime: 1000 * 60 * 5,
  });
};

export const useUpdateMe = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateRequest) => requireAuth(() => updateMe(data)),
    onSuccess(newUser) {
      queryClient.setQueryData(CURRENT_USER_QUERY, newUser);
    },
  });
};

export const useCreateList = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (list: Partial<UserList>) =>
      requireAuth(() =>
        axios.post(
          `${process.env.REACT_APP_SERVER_URL}/users/me/lists/${list.slug}`,
          { items: list.items },
          getAuthHeader()
        )
      ),
    onSuccess(res) {
      queryClient.invalidateQueries({
        queryKey: CURRENT_USER_QUERY,
      });
    },
  });
};

export const updateOrCreateListLocal = (
  lists: UserList[],
  newList: UserList
) => {
  const index = lists.findIndex((list) => list.slug === newList.slug);
  if (index === -1) {
    lists.push(newList);
  } else {
    lists[index] = newList;
  }
  return lists;
};

export const useUpdateList = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newList: Partial<UserList>) =>
      requireAuth(() => {
        return axios.post(
          process.env.REACT_APP_SERVER_URL + `/users/me/lists/${newList.slug!}`,
          {
            items: newList.items,
            hidden: newList.hidden,
            updateAutomatically: newList.updateAutomatically,
          },
          getAuthHeader()
        );
      }),
    onSuccess(res) {
      const { list, diff }: { list: UserList; diff: ListDiff } = res.data;
      queryClient.setQueryData(CURRENT_USER_QUERY, (existingUser: User) => {
        const diffs = existingUser.diffs;
        if (diff) {
          diffs.unshift(diff);
        }
        const newLists = updateOrCreateListLocal(existingUser.lists, list);
        return {
          ...existingUser,
          lists: newLists,
          diffs,
        };
      });
    },
  });
};

export const useRefreshList = (listSlug: string, integrationSlug: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      requireAuth(() =>
        axios.post(
          process.env.REACT_APP_SERVER_URL +
            `/users/me/lists/${listSlug}/refresh/${integrationSlug}`,
          {},
          getAuthHeader()
        )
      ),
    onSuccess(res) {
      const { list, diff }: { list: UserList; diff: ListDiff } = res.data;
      queryClient.setQueryData(CURRENT_USER_QUERY, (existingUser: User) => {
        const diffs = existingUser.diffs;
        if (diff) {
          diffs.unshift(diff);
        }
        return {
          ...existingUser,
          lists: updateOrCreateListLocal(existingUser.lists, list),
          diffs,
        };
      });
    },
  });
};

export const useDeleteList = (slug: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      requireAuth(() =>
        axios.delete(
          process.env.REACT_APP_SERVER_URL + `/users/me/lists/${slug}`,
          getAuthHeader()
        )
      ),
    onSuccess(res) {
      queryClient.setQueryData(CURRENT_USER_QUERY, (existingUser: User) => {
        return {
          ...existingUser,
          lists: existingUser.lists.filter((l) => l.slug !== slug),
        };
      });
    },
  });
};

export const useLogin = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      username,
      password,
    }: {
      username: string;
      password: string;
    }) =>
      axios.post(process.env.REACT_APP_SERVER_URL + "/auth/login", {
        username: username,
        password: password,
      }),
    onSuccess(res) {
      localStorage.setItem("accessToken", res.data.accessToken);
      localStorage.setItem("refreshToken", res.data.refreshToken);
      queryClient.setQueryData(CURRENT_USER_QUERY, res.data);
    },
  });
};

export const useLoginWithFirebase = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (accessToken: string) =>
      axios.post(process.env.REACT_APP_SERVER_URL + "/auth/firebase", {
        accessToken,
      }),
    onSuccess(res) {
      localStorage.setItem("accessToken", res.data.accessToken);
      localStorage.setItem("refreshToken", res.data.refreshToken);
      queryClient.setQueryData(CURRENT_USER_QUERY, res.data);
    },
  });
};

export const useSignUp = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SignUpRequest) => signUp(data),
    onSuccess(user) {
      localStorage.setItem("accessToken", user.accessToken || "");
      localStorage.setItem("refreshToken", user.refreshToken || "");
      queryClient.setQueryData(CURRENT_USER_QUERY, user);
      queryClient.invalidateQueries({
        queryKey: CURRENT_USER_QUERY,
      });
    },
  });
};

export const useWaitlist = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: WaitlistRequest) => signUpWaitlist(data),
    onSuccess(user) {
      localStorage.setItem("accessToken", user.accessToken || "");
      localStorage.setItem("refreshToken", user.refreshToken || "");
      queryClient.setQueryData(CURRENT_USER_QUERY, user);
    },
  });
};

export const useReorderLists = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (reorder: {
      singleColListIDs?: string[];
      leftColListIDs?: string[];
      rightColListIDs?: string[];
    }) => requireAuth(() => reorderLists(reorder)),
    onSuccess(newUser) {
      queryClient.setQueryData(CURRENT_USER_QUERY, newUser);
    },
  });
};

export const useDisconnectSpotify = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => requireAuth(() => disconnectSpotify()),
    onSuccess(newUser) {
      queryClient.setQueryData(CURRENT_USER_QUERY, newUser);
    },
  });
};

export const useFollowUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (username: string) => requireAuth(() => followUser(username)),
    onSuccess(data) {
      queryClient.setQueryData(CURRENT_USER_QUERY, (existingUser: User) => {
        return {
          ...existingUser,
          following: [...existingUser.following, data.followedID],
        };
      });
    },
  });
};

export const useUnfollowUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (username: string) => requireAuth(() => unfollowUser(username)),
    onSuccess(data) {
      queryClient.setQueryData(CURRENT_USER_QUERY, (existingUser: User) => {
        return {
          ...existingUser,
          following: existingUser.following.filter(
            (u) => u !== data.unfollowedID
          ),
        };
      });
    },
  });
};

export const useCreatePin = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createPin,
    onSuccess(pins: Pin[]) {
      queryClient.setQueryData(CURRENT_USER_QUERY, (existingUser: User) => {
        return {
          ...existingUser,
          pins,
        };
      });
    },
  });
};

export const useDeletePin = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deletePin,
    onSuccess(pins: Pin[]) {
      queryClient.setQueryData(CURRENT_USER_QUERY, (existingUser: User) => {
        return {
          ...existingUser,
          pins,
        };
      });
    },
  });
};
