import {
  Box,
  Center,
  Image as ChakraImage,
  FormControl,
  Icon,
  IconButton,
  Input,
  InputGroup,
  useToast,
} from "@chakra-ui/react";
import React, { ChangeEvent, useRef, useState } from "react";
import { FaImage, FaPen, FaUser } from "react-icons/fa";
import { errorToast } from "src/utils";

const MAX_FILE_SIZE = 5; // 5 MB
const MAX_IMAGE_DIMENSION = 500; // 500 px

interface ProfilePictureUploadProps {
  setProfilePicture: (file: File) => void;
  existingProfilePicture?: string;
  size?: number;
}

const ProfilePictureUpload: React.FC<ProfilePictureUploadProps> = ({
  setProfilePicture,
  existingProfilePicture,
  size,
}) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(
    existingProfilePicture || null
  );

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const toast = useToast();

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const fileType = file.type.split("/")[1]; // e.g., 'image/png'
      const fileSize = file.size / (1024 * 1024); // in MB

      if (fileType !== "png" && fileType !== "jpeg" && fileType !== "jpg") {
        errorToast("File type must be png, jpeg, or jpg.", toast);
      } else if (fileSize > MAX_FILE_SIZE) {
        // Compress the image if it exceeds the maximum size
        compressImage(file)
          .then((compressedFile) => {
            setProfilePicture(compressedFile as File);
            const reader = new FileReader();
            reader.onloadend = () => {
              setSelectedImage(reader.result as string);
            };
            reader.readAsDataURL(compressedFile as File);
          })
          .catch((error) => {
            console.error("Error compressing image:", error);
            errorToast("Error compressing image.", toast);
          });
      } else {
        setProfilePicture(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    }

    // Function to compress the image iterativly
    function compressImage(file: File) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img: HTMLImageElement = document.createElement("img");
          img.src = event.target?.result as string; // Check if event.target exists
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > MAX_IMAGE_DIMENSION) {
                height *= MAX_IMAGE_DIMENSION / width;
                width = MAX_IMAGE_DIMENSION;
              }
            } else {
              if (height > MAX_IMAGE_DIMENSION) {
                width *= MAX_IMAGE_DIMENSION / height;
                height = MAX_IMAGE_DIMENSION;
              }
            }

            canvas.width = width;
            canvas.height = height;

            ctx?.drawImage(img, 0, 0, width, height);

            let quality = 0.9; // Initial quality setting
            let qualityStep = 0.1; // Step for adjusting quality

            const compress = () => {
              canvas.toBlob(
                (blob) => {
                  if (blob && blob.size > MAX_FILE_SIZE * 1024 * 1024) {
                    quality -= qualityStep;
                    if (quality < 0.1) {
                      qualityStep /= 2; // Reduce step for finer adjustment
                    }
                    canvas.toBlob(compress, file.type, quality);
                  } else {
                    if (blob) {
                      const compressedFile = new File(
                        [blob as Blob],
                        file.name,
                        {
                          type: file.type,
                          lastModified: Date.now(),
                        }
                      );
                      resolve(compressedFile);
                    } else {
                      reject(
                        "Error compressing image: Unable to reach target file size."
                      );
                    }
                  }
                },
                file.type,
                quality
              ); // Initial quality (0.9 is high quality)
            };

            compress();
          };
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleSelectImageClick = () => {
    // Trigger the file input click event when "Select Image" is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Box>
      <FormControl>
        <InputGroup>
          <Center onClick={handleSelectImageClick}>
            {selectedImage ? (
              <ChakraImage
                src={selectedImage}
                alt="Profile Picture"
                borderRadius="full"
                boxSize={size ? size + "em" : "10em"}
                cursor="pointer"
                objectFit="cover"
              />
            ) : (
              <Box
                boxSize={size ? size + "em" : "10em"}
                borderRadius="full"
                backgroundColor="gray.200"
                display="flex"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
              >
                <Icon
                  as={FaUser}
                  boxSize={size ? size / 2.5 + "em" : "3em"}
                  color="gray.400"
                />
              </Box>
            )}
            <IconButton
              icon={<Icon as={selectedImage ? FaPen : FaImage}></Icon>}
              p="0"
              color="gray.400"
              aria-label="Edit profile picture"
              size="sm"
              borderRadius="full"
              position="absolute"
              bottom="-2px"
              right="-2px"
              onClick={handleSelectImageClick}
            />
          </Center>
          <Input
            type="file"
            name="profile-pic"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleImageChange}
            accept="image/*"
          />
        </InputGroup>
      </FormControl>
    </Box>
  );
};

export default ProfilePictureUpload;
