import {
  Box,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useSortable } from "@dnd-kit/sortable";
import { motion } from "framer-motion";
import React from "react";
import {
  FaChevronRight,
  FaEyeSlash,
  FaRegGem,
  FaSpotify,
  FaTrashCan,
} from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { ActionButton } from "src/components/Buttons";
import PodiumsCard from "src/components/PodiumsCard";
import {
  BACKDROP_BLUR,
  BACKGROUND_DARK_GRADIENT_COLORED,
  BACKGROUND_HIDDEN,
  blackAfter,
  blackBefore,
  borderRadiusDisabledUsers,
  IMAGE_PADDING_LISTS,
} from "src/constants";
import { useCurrentUser } from "src/state/userQueries";
import { UserList } from "src/types/list";
import { UserTheme } from "src/types/userThemes";
import { stringToPastelColor } from "src/utils";
import { useDeleteListToast } from "../Edit/EditList";
import { DeleteListDialog, SpotifyDialog } from "../Utils/ListDialogs";
import ListGrid from "./ListGrid";

const ListButtton: React.FC<{
  userList: UserList;
  username: string;
  itemLimit?: number;
  itemImageHeight?: any;
  listGridColumns?: number;
  marginBottom?: string;
  tallImages?: boolean;
  theme: UserTheme;
  isEditing: boolean;
}> = ({
  userList,
  username,
  itemLimit,
  itemImageHeight,
  listGridColumns,
  marginBottom,
  tallImages,
  theme,
  isEditing,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: userList._id ?? "" });

  const style = {
    transform: isDragging
      ? `scale(1.05) translate3d(${transform?.x}px, ${transform?.y}px, 0)`
      : undefined,
    transition,
    // transition: isDragging ? "transform 0.2s ease" : undefined,
  };

  const spotifyDisclosure = useDisclosure();
  const deleteDisclosure = useDisclosure();
  const limitedDisclosure = useDisclosure();
  const { data: currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const onDelete = useDeleteListToast(userList, () => {});

  const isHidden = userList.hidden;
  const showSpotify = userList.integrationProviderSlug === "spotify";
  const isLimited = userList.limitedEdition;

  const background = userList.hidden
    ? BACKGROUND_HIDDEN
    : theme.name === "Pastel"
    ? stringToPastelColor(userList.slug)
    : theme.name === "Black"
    ? BACKGROUND_DARK_GRADIENT_COLORED
    : theme.cardBg;

  return (
    <Box width="100%" ref={setNodeRef} pos="relative" mb={marginBottom}>
      <motion.div
        layoutId={`list-${userList.slug}`}
        // transition={isDragging ? { duration: 0 } : { duration: 0.3 }}
      >
        <PodiumsCard
          align="start"
          p="0.75em"
          bg={background}
          backdropFilter="auto"
          backdropBlur={BACKDROP_BLUR}
          onClick={(e: any) => {
            // e.preventDefault();
            if (isDragging || isEditing) return;
            navigate(`/${username}/${userList.slug}`);
          }}
          boxShadow={
            isDragging
              ? "0px 12px 20px 10px rgba(0,0,0,0.2)"
              : theme.cardShadow ?? "md"
          }
          borderWidth={theme.cardBorderColor !== "none" ? "1px" : 0}
          borderColor={theme.cardBorderColor}
          cursor={isDragging || isEditing ? undefined : "pointer"}
          style={style}
          boxSizing="border-box"
          overflow="hidden"
          _before={theme.name === "Black" ? blackBefore : undefined}
          _after={theme.name === "Black" ? blackAfter : undefined}
        >
          <VStack spacing={2} alignItems="start" width="100%" pos="relative">
            <HStack spacing="6px" width="100%">
              {isHidden && (
                <Icon
                  as={FaEyeSlash}
                  boxSize="18px"
                  style={{ color: theme.textColor }}
                />
              )}
              {showSpotify && (
                <Icon
                  as={FaSpotify}
                  boxSize="18px"
                  style={{ color: theme.textColor }}
                  onClick={(e) => {
                    e.preventDefault();
                    spotifyDisclosure.onOpen();
                  }}
                  cursor="pointer"
                />
              )}
              {isLimited && (
                <Icon
                  as={FaRegGem}
                  boxSize="16px"
                  style={{ color: theme.textColor }}
                  onClick={(e) => {
                    e.preventDefault();
                    limitedDisclosure.onOpen();
                  }}
                />
              )}
              <Box
                overflow="hidden"
                whiteSpace="nowrap"
                style={{
                  maskImage:
                    "linear-gradient(to right, black 75%, transparent 100%)",
                }}
                w={`calc(100% - ${
                  27 * [isHidden, showSpotify, isLimited].filter(Boolean).length
                }px)`}
              >
                <motion.text layoutId={userList.name}>
                  <Heading fontSize="14px" color={theme.textColor}>
                    {userList.name}
                  </Heading>
                </motion.text>
              </Box>
              <Icon
                as={FaChevronRight}
                boxSize="16px"
                style={{ color: theme.textColor }}
              />
            </HStack>
            <HStack width="100%">
              {isEditing && (
                <VStack alignItems="center" ml="-4px" mt="-20px">
                  <ActionButton
                    icon={FaTrashCan}
                    fontSize="20px"
                    width="auto"
                    onClick={deleteDisclosure.onOpen}
                    color={theme.textColor}
                  />
                  <Grid
                    templateColumns="repeat(2, 1fr)"
                    gap={1}
                    cursor="grab"
                    {...listeners}
                    {...attributes}
                  >
                    {[...Array(6)].map((_, index) => (
                      <GridItem
                        boxSize="5px"
                        bg={theme.textColor}
                        borderRadius="full"
                      />
                    ))}
                  </Grid>
                </VStack>
              )}
              <Box mr={isEditing ? "-30px" : 0}>
                <ListGrid
                  items={userList.items.slice(
                    0,
                    Math.min(itemLimit ?? 5, userList.items.length)
                  )}
                  imageHeight={itemImageHeight}
                  bgColor={userList.hidden ? "topFive.gray.light" : "white"}
                  borderRadius={
                    localStorage.getItem("borderRadiusDisabled") !== "true" ||
                    borderRadiusDisabledUsers.includes(username)
                  }
                  columns={listGridColumns}
                  tallImages={tallImages}
                  isDragging={isDragging}
                  imagePadding={
                    IMAGE_PADDING_LISTS.includes(userList.slug) ? 1 : 0
                  }
                  enableDetailModal={!isEditing}
                />
              </Box>
            </HStack>
          </VStack>
        </PodiumsCard>
      </motion.div>
      <SpotifyDialog
        listSlug={userList.slug}
        disclosure={spotifyDisclosure}
        isUpdatingAutomatically={userList.updateAutomatically ?? false}
        onMyProfile={currentUser?.username === username}
      />
      <DeleteListDialog
        deleteDisclosure={deleteDisclosure}
        onDelete={onDelete}
        listName={userList.name}
      />
    </Box>
  );
};

export default ListButtton;
