import { VStack } from "@chakra-ui/react";
import React from "react";

import { TALL_IMAGE_LISTS } from "src/constants";
import { UserList } from "src/types/list";
import { UserTheme } from "src/types/userThemes";
import { SortableList } from "../Utils/SortableList";
import ListButton from "./ListButton";

const OneColumnListOfLists: React.FC<{
  username: string;
  theme: UserTheme;
  lists: UserList[];
  setLists: (l: UserList[]) => void;
  isEditing: boolean;
}> = ({ username, lists, setLists, theme, isEditing }) => {
  return (
    <VStack width="100%" maxW="450px" borderRadius="xl" spacing={3}>
      <SortableList
        items={lists.map((item) => item._id ?? "")}
        setItems={setLists}
      >
        {lists.map((list) => (
          <ListButton
            username={username}
            userList={list}
            key={list.slug}
            tallImages={TALL_IMAGE_LISTS.includes(list.slug)}
            theme={theme}
            isEditing={isEditing}
          />
        ))}
      </SortableList>
    </VStack>
  );
};
export default OneColumnListOfLists;
