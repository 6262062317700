import { Card, Center } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { BACKDROP_BLUR, BACKGROUND_FADED } from "src/constants";
import { Header } from "./Header/Header";
import GradientBackground from "./User/UserBackground";

const PodiumsCard: React.FC<{ children?: ReactNode; [key: string]: any }> = ({
  children,
  ...rest
}) => {
  return (
    <Card
      bgColor="white"
      p="1em"
      align="center"
      borderRadius="12px"
      width="100%"
      maxWidth="450px"
      bg={BACKGROUND_FADED}
      backdropFilter="auto"
      backdropBlur={BACKDROP_BLUR}
      boxShadow="md"
      {...rest}
    >
      {children}
    </Card>
  );
};

export const PodiumsWrap: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <GradientBackground>
      <Header />
      <Center width="100%">
        <PodiumsCard p="2em" mx="1em">
          {children}
        </PodiumsCard>
      </Center>
    </GradientBackground>
  );
};

export default PodiumsCard;
