import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { Header, Logo } from "src/components/Header/Header";
import { ProfilePicture } from "src/components/User/UserProfileHeader";
import { useCurrentUser } from "src/state/userQueries";

const DeviceMockup: React.FC<{ imageURL: string; [key: string]: any }> = ({
  imageURL,
}) => {
  return (
    <Box
      height={{ base: "auto", md: "100%" }}
      overflow="hidden"
      pt={{ base: "0em", md: "7vh" }}
      px="1em"
      mb={{ base: "-3.5em", md: "0em" }}
    >
      <Image
        src={imageURL}
        maxHeight={{ base: "60vh", md: "105vh" }}
        mx="auto"
      />
    </Box>
  );
};

const HomeSection: React.FC<{
  title: string;
  subtitle: string;
  imageURL: string;
  right?: boolean;
  showLogo?: boolean;
}> = ({ title, subtitle, imageURL, right, showLogo }) => {
  const { data: user, isLoading, isError } = useCurrentUser();
  return (
    <Box
      width="100%"
      bg={right ? undefined : "white"}
      className={right ? "bg-party-gradient" : undefined}
    >
      <Container maxW="1300px">
        <Flex
          height={{ base: "auto", md: "100vh" }}
          direction={{ base: "column", md: right ? "row-reverse" : "row" }}
          align="center"
          width="100%"
          color={right ? "white" : "topFive.primary"}
          px="1em"
          overflow="hidden"
        >
          <VStack
            spacing={8}
            align={{ base: "center", md: "start" }}
            w={{ base: "100%", md: "50%" }}
            py="2em"
          >
            {showLogo && (
              <Box
                boxSize="90px"
                justifyContent="center"
                alignItems="center"
                display="flex"
                borderRadius="15px"
                className="bg-party-gradient"
              >
                <Logo color="white" height="55px" linkDisabled />
              </Box>
            )}
            <Heading
              as="h2"
              size="3xl"
              textAlign={{ base: "center", md: "start" }}
              color={right ? "white" : "topFive.primary"}
            >
              {title}
            </Heading>
            <Text
              fontSize="2xl"
              align="left"
              textAlign={{ base: "center", md: "start" }}
              color={right ? "white" : "topFive.primary"}
            >
              {subtitle}
            </Text>
            {!user || isError ? (
              <Button
                borderRadius="full"
                color={right ? "topFive.primary" : "white"}
                className={right ? undefined : "bg-party-gradient-button"}
                px="2em"
                size="lg"
                as={Link}
                fontWeight={600}
                to="/signup"
              >
                Sign Up
              </Button>
            ) : (
              <Button
                borderRadius="full"
                color={right ? "topFive.primary" : "white"}
                className={right ? undefined : "bg-party-gradient-button"}
                size="lg"
                as={Link}
                fontWeight={600}
                to="/me"
                leftIcon={
                  <ProfilePicture src={user.profilePicture} size={1.5} />
                }
              >
                Go to My Profile
              </Button>
            )}
          </VStack>
          <Box height="100%" w={{ base: "100%", md: "50%" }}>
            <DeviceMockup imageURL={imageURL} />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

const HomePage: React.FC = () => {
  return (
    <VStack width="100%" spacing={0} bg="topFive.primary">
      <Header logoColor="white" />

      <HomeSection
        title="Share your favorites"
        subtitle="Showcase your top songs, artists, movies, and more, all on one platform."
        imageURL="/profile-screenshot.png"
        showLogo
      />
      <HomeSection
        title="Create lists in seconds"
        subtitle="Import songs & artists from Spotify, and create more lists with ease."
        imageURL="/list-screenshot.png"
        right
      />
      <HomeSection
        title="Explore more together"
        subtitle="Discover new favorites and connect with others who share your interests."
        imageURL="/feed-screenshot.png"
      />
      <HomeSection
        title="Share your link with the world"
        subtitle="Whether it's TikTok, Instagram, or Tinder, show your friends who you really are with your own personal link."
        imageURL="/link-screenshot.png"
        right
      />
    </VStack>
  );
};

export default HomePage;
