import {
  Center,
  Heading,
  UseDisclosureReturn,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import "src/backgrounds.css";
import OneColumnListOfLists from "src/components/Lists/View/OneColumnListOfLists";
import PodiumsCard from "src/components/PodiumsCard";
import { FloatingCreateButton } from "src/components/User/FloatingCreateButton";
import { useReorderLists } from "src/state/userQueries";
import { UserList } from "src/types/list";
import { SanitizedOtherUser } from "src/types/user";
import { getTheme } from "src/types/userThemes";
import TwoColumnListOfLists from "./TwoColumnListOfLists";

const ListOfLists: React.FC<{
  user: SanitizedOtherUser;
  onMyProfile: boolean;
  localIsSingleColumn?: boolean;
  editListsDisclosure: UseDisclosureReturn;
}> = ({ user, onMyProfile, localIsSingleColumn, editListsDisclosure }) => {
  const toast = useToast();

  const reorderLists = useReorderLists();

  const [lists, setLists] = useState<UserList[]>(user.lists);
  const [leftColLists, setLeftColLists] = useState<UserList[]>(
    user.leftColLists ?? []
  );
  const [rightColLists, setRightColLists] = useState<UserList[]>(
    user.rightColLists ?? []
  );

  const onSaveLists = () => {
    if (user.listLayout.length === 1) {
      saveListsSingleColumn();
    } else {
      saveLists2Column();
    }
    editListsDisclosure.onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <Center width="100%" position="relative" pt={3} pb="2em">
      <VStack position="relative" spacing={4} width="100%">
        {lists.length > 0 ? (
          <Center width="100%" px={3}>
            {localIsSingleColumn ?? user.listLayout.length === 1 ? (
              <OneColumnListOfLists
                username={user?.username ?? ""}
                lists={lists}
                setLists={setLists}
                theme={getTheme(user.backgroundType)}
                isEditing={editListsDisclosure.isOpen}
              />
            ) : (
              <TwoColumnListOfLists
                username={user?.username ?? ""}
                leftCol={leftColLists}
                rightCol={rightColLists}
                setLeftCol={setLeftColLists}
                setRightCol={setRightColLists}
                theme={getTheme(user.backgroundType)}
                isEditing={editListsDisclosure.isOpen}
              />
            )}
          </Center>
        ) : (
          <Center width="100%" px={3}>
            <PodiumsCard>
              <Heading size="4xl">😥</Heading>
              <Heading color="topFive.black">
                {onMyProfile ? "You have" : `${user?.username} has`} no lists!
              </Heading>
            </PodiumsCard>
          </Center>
        )}
        {onMyProfile && user && (
          <FloatingCreateButton
            isEditingLists={editListsDisclosure.isOpen}
            onSaveLists={onSaveLists}
          />
        )}
      </VStack>
    </Center>
  );

  function saveLists2Column() {
    // check if lists were reordered
    if (
      leftColLists.every((l, i) => l._id === user.listLayout[0][i]) &&
      rightColLists.every((l, i) => l._id === user.listLayout[1][i]) &&
      leftColLists.length === user.listLayout[0].length &&
      rightColLists.length === user.listLayout[1].length
    ) {
      console.log("same");
      return;
    }
    toast.promise(
      reorderLists.mutateAsync({
        leftColListIDs: leftColLists.map((l) => l._id ?? ""),
        rightColListIDs: rightColLists.map((l) => l._id ?? ""),
      }),
      {
        success: { title: "Lists saved!" },
        loading: { title: "Loading..." },
        error(err) {
          return {
            title: "Error",
            description: err.message,
          };
        },
      }
    );
  }
  function saveListsSingleColumn() {
    // check if lists were reordered
    if (lists.every((l, i) => l._id === user.listLayout[0][i])) {
      return;
    }
    toast.promise(
      reorderLists.mutateAsync({
        singleColListIDs: lists?.map((l) => l._id ?? ""),
      }),
      {
        success: { title: "Lists saved!" },
        loading: { title: "Loading..." },
        error(err) {
          return {
            title: "Error",
            description: err.message,
          };
        },
      }
    );
  }
};

export default ListOfLists;
