import {
  Box,
  Button,
  Center,
  Divider,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaCheckCircle, FaInfoCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Header } from "src/components/Header/Header";
import PodiumsCard, { PodiumsWrap } from "src/components/PodiumsCard";
import GradientBackground from "src/components/User/UserBackground";
import CreateListFromScratch from "src/pages/CreateListFromScratch";
import { useGetListOptions } from "src/state/listQueries";
import { useCurrentUser } from "src/state/userQueries";
import OAuthLoginButton from "../components/Auth/OAuthLoginButton";
import { BackButton } from "../components/Buttons";
import ListIcon from "../components/Lists/Utils/ListIcon";
import { ShimmerListOptions } from "../components/Shimmer";
import { ListOption } from "../types/list";

enum Page {
  LIST_OPTIONS,
  INTEGRATION_OR_SEARCH,
  CREATE_LIST,
}

export const CreateList = () => {
  const [selectedListOption, setSelectedList] = useState<ListOption | null>(
    null
  );
  const [currentPage, setCurrentPage] = useState<Page>(Page.LIST_OPTIONS);

  const userQuery = useCurrentUser();
  const listOptionsQuery = useGetListOptions();
  const navigate = useNavigate();
  const toast = useToast();

  const selectedProviders =
    selectedListOption?.integrationProviders.filter(
      (provider) => provider.myItemsURL
    ) ?? [];

  if (listOptionsQuery.isLoading || userQuery.isLoading) {
    return (
      <PodiumsWrap>
        <VStack width="100%" spacing={3}>
          <Heading textAlign="center" fontSize="30px">
            Create a list
          </Heading>
          <ShimmerListOptions />
        </VStack>
      </PodiumsWrap>
    );
  }

  if (
    !listOptionsQuery.data ||
    listOptionsQuery.isError ||
    !userQuery.data ||
    userQuery.isError
  ) {
    return (
      <PodiumsWrap>
        <VStack width="100%" spacing={3}>
          <Heading textAlign="center" fontSize="30px">
            Error
          </Heading>
        </VStack>
      </PodiumsWrap>
    );
  }

  const lists = listOptionsQuery.data;
  const user = userQuery.data;

  const userListSlugs = user.lists.map((lst) => lst.slug);

  if (currentPage === Page.LIST_OPTIONS || selectedListOption === null) {
    return (
      <GradientBackground>
        <Header logoColor="white" />
        <Center width="100%">
          <PodiumsCard p="2em" mx="1em" color="topFive.black">
            <VStack width="100%" spacing={3}>
              <HStack
                width="100%"
                position="relative"
                alignContent="center"
                justifyContent="center"
              >
                <Box position="absolute" left="0">
                  <BackButton to="/me" />
                </Box>
                <Heading
                  textAlign="center"
                  fontSize="30px"
                  color="topFive.black"
                >
                  Create a list
                </Heading>
              </HStack>
              <Box width="100%">
                {Object.keys(lists).map((category) => (
                  <Box mb="1em">
                    <HStack alignContent="center" mb="0.5em" spacing={1.5}>
                      <Heading
                        key={category}
                        fontSize="1em"
                        color="topFive.black"
                      >
                        {category.replaceAll("-", " ").toUpperCase()}
                      </Heading>
                      {category === "limited-edition" && (
                        <Icon
                          as={FaInfoCircle}
                          fontSize="1.25em"
                          cursor="pointer"
                          onClick={() => {
                            toast({
                              title: "Limited Edition List",
                              description:
                                "This list is only available for a limited time. Create your list while you can!",
                              status: "info",
                              duration: 5000,
                              isClosable: true,
                            });
                          }}
                          style={{ color: "topFive.black" }}
                        ></Icon>
                      )}
                    </HStack>
                    <Grid
                      templateColumns={
                        category === "limited-edition"
                          ? "repeat(1, 1fr)"
                          : lists[category].length % 2 === 0 ||
                            lists[category].length === 1
                          ? "repeat(2, 1fr)"
                          : "repeat(3, 1fr)"
                      }
                      gap={4}
                    >
                      {lists[category].map((list) => {
                        const listAlreadyExists = userListSlugs.includes(
                          list.slug
                        );
                        return (
                          <GridItem
                            borderRadius="lg"
                            onClick={() => {
                              if (listAlreadyExists) {
                                navigate("/me/" + list.slug);
                                return;
                              }
                              if (
                                list.integrationProviders.filter(
                                  (provider) => provider.myItemsURL
                                ).length === 0
                              ) {
                                setCurrentPage(Page.CREATE_LIST);
                              } else {
                                setCurrentPage(Page.INTEGRATION_OR_SEARCH);
                              }
                              setSelectedList(list);
                            }}
                            key={list.slug}
                            p="0.75em"
                            cursor="pointer"
                            bg={
                              category === "limited-edition"
                                ? undefined
                                : listAlreadyExists
                                ? "#634AFF"
                                : "gray.100"
                            }
                            className={
                              category === "limited-edition"
                                ? "bg-party-gradient"
                                : undefined
                            }
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            gap={1}
                            minH="80px"
                          >
                            {listAlreadyExists ? (
                              <Icon
                                as={FaCheckCircle}
                                boxSize="1.5em"
                                style={{ color: "white" }}
                              />
                            ) : (
                              <ListIcon
                                iconName={list.slug}
                                color={
                                  category === "limited-edition"
                                    ? "white"
                                    : undefined
                                }
                                boxSize="1.5em"
                              />
                            )}
                            <Heading
                              fontSize="0.9em"
                              textAlign="center"
                              lineHeight="1.3em"
                              color={
                                category === "limited-edition" ||
                                listAlreadyExists
                                  ? "white"
                                  : "topFive.black"
                              }
                            >
                              {list.name}
                            </Heading>
                          </GridItem>
                        );
                      })}
                    </Grid>
                  </Box>
                ))}
              </Box>
            </VStack>
          </PodiumsCard>
        </Center>
      </GradientBackground>
    );
  } else if (currentPage === Page.CREATE_LIST) {
    return (
      <CreateListFromScratch
        slug={selectedListOption.slug}
        name={selectedListOption.name}
        onBack={() => {
          setCurrentPage(Page.LIST_OPTIONS);
          setSelectedList(null);
        }}
      />
    );
  } else {
    return (
      <GradientBackground>
        <Header />
        <Center minH="80vh" width="100%">
          <PodiumsCard p="2em" m="1em">
            <VStack width="100%" spacing={3}>
              <HStack justifyContent="center" mb="4px" spacing={3}>
                <Box position="absolute" left="1.5em">
                  <BackButton onClick={() => setSelectedList(null)} />
                </Box>
                <ListIcon
                  iconName={selectedListOption.slug}
                  color="#000"
                  boxSize="1.5em"
                />
                <Heading>{selectedListOption.name}</Heading>
              </HStack>
              {selectedProviders.map((provider) => (
                <div key={provider.slug}>
                  <OAuthLoginButton
                    provider={provider}
                    listSlug={selectedListOption.slug}
                    navigate={navigate}
                    key={provider.slug}
                    user={user}
                  />
                </div>
              ))}
              {selectedListOption.apiProvider.length !== 0 &&
                selectedProviders.length !== 0 && (
                  <HStack position="relative" padding="1em 1em" width="100%">
                    <Divider borderColor="gray.700" />
                    <Text fontWeight={700} color="gray.700">
                      OR
                    </Text>
                    <Divider borderColor="gray.700" />
                  </HStack>
                )}
              {selectedListOption.apiProvider.length !== 0 && (
                <Button
                  onClick={() => {
                    setCurrentPage(Page.CREATE_LIST);
                    setSelectedList(selectedListOption);
                  }}
                >
                  Search for {selectedListOption.name}...
                </Button>
              )}
            </VStack>
          </PodiumsCard>
        </Center>
      </GradientBackground>
    );
  }
};
