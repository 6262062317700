export const TALL_IMAGE_LISTS = ["movies", "shows", "books"];
export const IMAGE_PADDING_LISTS = ["teams"];
export const phoneRegex = /^\+?1?\d{10}$/;
export const borderRadiusDisabledUsers = ["spotify.demo"];
export const BACKDROP_BLUR = "6px";
export const BACKGROUND_FADED = "rgba(255,255,255,0.85)";
export const BACKGROUND_HIDDEN = "rgba(144, 144, 150,0.6)";
export const BACKGROUND_DARK_GRADIENT =
  "linear-gradient(144deg, #FFB6D7 0%, rgba(146, 188, 245, 0.1) 100%)";
export const BACKGROUND_DARK_GRADIENT_COLORED =
  "linear-gradient(144deg, rgba(255,253,125,1) 0%, rgba(255,182,215,0.7) 31%, rgba(91,154,255,0.6) 100%)";
export const blackBefore = {
  content: '""',
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background:
    "linear-gradient(144deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.4) 100%)", // Dark gradient
  borderRadius: "12px",
  margin: "2px", // Adjust based on the border thickness
  zIndex: -1,
  pointerEvents: "none",
};
export const blackAfter = {
  content: '""',
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  borderRadius: "12px", // Match the same border-radius
  margin: "2px", // Adjust based on the border thickness
  background:
    "linear-gradient(144deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.95) 10%, rgba(0, 0, 0, 0.9) 20%, rgba(0, 0, 0, 0.85) 30%, rgba(0, 0, 0, 0.8) 40%, rgba(0, 0, 0, 0.75) 50%, rgba(0, 0, 0, 0) 100%)",
  zIndex: -1,
  pointerEvents: "none",
};
