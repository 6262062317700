import { ToastId, UseToastOptions } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { CURRENT_USER_QUERY } from "./state/userQueries";
import { ListItem, UserList } from "./types/list";
import { SanitizedOtherUser } from "./types/user";

export const errorToast = (
  err: string,
  toast: (options?: UseToastOptions | undefined) => ToastId,
  position: "top" | "bottom" = "bottom"
) => {
  toast({
    title: "Error",
    description: err,
    status: "error",
    duration: 3000,
    isClosable: true,
    position,
  });
};

export const isColorLight = (hexColor: string) => {
  // Convert the color to RGB format
  const rgb = hexToRgb(hexColor);

  // Calculate the luminance using the relative luminance formula
  const luminance = 0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b;

  // Determine if the color is light or dark based on luminance
  return luminance > 128;
};

const hexToRgb = (hex: string) => {
  // Remove the hash if present
  hex = hex.replace(/^#/, "");

  // Parse the hex values for red, green, and blue
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Return an object with the RGB values
  return { r, g, b };
};

export const listNameToSlug = (name: string): string => {
  return name.toLowerCase().replaceAll(" ", "-");
};

export const relativeDateShort = (date: string) => {
  var delta = Math.round((+new Date() - Date.parse(date)) / 1000);
  var minute = 60,
    hour = minute * 60,
    day = hour * 24,
    week = day * 7;

  let fuzzy;

  if (delta < minute) {
    fuzzy = "now";
  } else if (delta < hour) {
    fuzzy = Math.floor(delta / minute) + "m";
  } else if (Math.floor(delta / hour) === 1) {
    fuzzy = "1h";
  } else if (delta < day) {
    fuzzy = Math.floor(delta / hour) + "h";
  } else if (delta < day * 2) {
    fuzzy = "1d";
  } else if (delta < week) {
    fuzzy = Math.floor(delta / day) + "d";
  } else {
    fuzzy = Math.floor(delta / week) + "w";
  }
  return fuzzy;
};

export const relativeDate = (date: string) => {
  var delta = Math.round((+new Date() - Date.parse(date)) / 1000);
  var minute = 60,
    hour = minute * 60,
    day = hour * 24,
    week = day * 7;

  let fuzzy;

  if (delta < minute) {
    fuzzy = "just now";
  } else if (delta < hour) {
    const mins = Math.floor(delta / minute);
    fuzzy = mins + ` minute${mins === 1 ? "" : "s"} ago`;
  } else if (Math.floor(delta / hour) === 1) {
    fuzzy = "1 hour ago";
  } else if (delta < day) {
    fuzzy = Math.floor(delta / hour) + " hours ago";
  } else if (delta < day * 2) {
    fuzzy = "yesterday";
  } else if (delta < week) {
    fuzzy = Math.floor(delta / day) + " days ago";
  } else {
    const weeks = Math.floor(delta / week);
    fuzzy = weeks + ` week${weeks === 1 ? "" : "s"} ago`;
  }
  return fuzzy;
};

export const useLogout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return async () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    queryClient.setQueryData(CURRENT_USER_QUERY, null);
    navigate("/");
  };
};

export const listItemsChanged = (listA: ListItem[], listB: ListItem[]) => {
  if (listA.length !== listB.length) return true;
  return listA.some((v, i) => v.title !== listB[i].title);
};

export const listsChanged = (listA: UserList[], listB: UserList[]) => {
  if (listA.length !== listB.length) return true;
  return listA.some((v, i) => v._id !== listB[i]._id);
};

export const compareSearchResults = (
  userA: SanitizedOtherUser,
  userB: SanitizedOtherUser,
  recentSearches: string[]
) => {
  const a = userA.username;
  const b = userB.username;
  const aIndex = recentSearches.indexOf(a);
  const bIndex = recentSearches.indexOf(b);

  // If both are recent searches, sort them based on their index in the recent searches array
  if (aIndex !== -1 && bIndex !== -1) {
    return aIndex - bIndex;
  }

  // If only one of them is a recent search, prioritize it
  if (aIndex !== -1) return -1;
  if (bIndex !== -1) return 1;

  // If neither is a recent search, sort them alphabetically
  return a.localeCompare(b);
};

export const getItemSortingID = (item: ListItem) => {
  return item._id ?? item.title + item.imageURI;
};

const pastelColors = [
  "#ffb3ba",
  "#ffdfba",
  "#ffffba",
  "#baffc9",
  "#bae1ff", // Soft reds, oranges, yellows, greens, blues
  "#c9c9ff",
  "#ffccf9",
  "#ffcccc",
  "#f4cccc",
  "#ffe6cc", // Purples and pinks
  "#f9ccff",
  "#ccf5ff",
  "#ccffeb",
  "#ffffcc",
  "#f3e6ff", // Soft blues, greens, purples
  "#ffbaff",
  "#bafaff",
  "#ffebba",
  "#d1e7ff",
  "#ffecba", // Light pastels with more variance
  "#d0ffba",
  "#ffe5ba",
  "#ffbad1",
  "#ffdad1",
  "#bafff0", // More pastels with green, pink hues
  "#c1ffd7",
  "#ffd1e3",
  "#ffe0ba",
  "#ffd6cc",
  "#ccffe1", // Additional mix of pastels
  "#ffbadd",
  "#d6ffba",
  "#c5ffba",
  "#bad1ff",
  "#ffbac5", // Pink, green, blue hues
  "#bae8ff",
  "#f0ffba",
  "#ffbaf1",
  "#ffbad6",
  "#bae1ff", // Lighter pastel variations
  "#d1baff",
  "#ffccd9",
  "#ffd6ff",
  "#ccecff",
  "#ffedba", // More purple, pinks, yellows
  "#d1ffe1",
  "#ffebd1",
  "#baffff",
  "#fff0ba",
  "#ffbad6", // Final pastel colors in range
];

export const stringToPastelColor = (str: string) => {
  // Hash function to generate a consistent number based on the input string
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 1) - hash);
  }

  // Convert the hash to a valid index in the pastelColors array
  const index = Math.abs(hash) % pastelColors.length;

  // Return the corresponding pastel color
  return pastelColors[index];
};
