import {
  Button,
  ButtonProps,
  Flex,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Link } from "react-router-dom";
import { OAuthIntegrationProvider, User } from "src/types/user";
import { refreshIntegration } from "../../networking";
import { CURRENT_USER_QUERY } from "../../state/userQueries";
import { isColorLight } from "../../utils";
import ListIcon from "../Lists/Utils/ListIcon";

interface OAuthButtonProps extends ButtonProps {
  navigate: (path: string) => void;
  provider: OAuthIntegrationProvider;
  listSlug: string;
  user?: User;
}

const OAuthLoginButton: React.FC<OAuthButtonProps> = ({
  navigate,
  provider,
  listSlug,
  user,
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const hoverBgColor = useColorModeValue(
    `${provider.color}.darken(10%)`,
    `${provider.color}.darken(10%)`
  );

  const handleRefreshIntegration = async () => {
    const refreshPromise = refreshIntegration(
      navigate,
      listSlug,
      provider.slug
    );
    toast.promise(refreshPromise, {
      success: (data) => {
        if (!data) {
          console.warn("Unable to refresh integration");
          navigate(process.env.REACT_APP_SERVER_URL + "/auth/" + provider.slug);
          return {
            title: "Please reauthenticate with " + provider.name,
            description: "You may have to enable popups!",
            status: "warning",
          };
        }
        queryClient.invalidateQueries({ queryKey: CURRENT_USER_QUERY });
        setTimeout(() => navigate("/me/" + listSlug), 500);
        return { title: "Items pulled from " + provider.name + "!" };
      },
      error: { title: "Error", description: "Error updating items" },
      loading: { title: "Loading..." },
    });
  };

  const textColor = isColorLight(provider.color) ? "black" : "white";

  if (
    user &&
    user.oauthIntegrations.find((i) => i.providerSlug === provider.slug)
  ) {
    return (
      <Button
        onClick={handleRefreshIntegration}
        backgroundColor={provider.color}
        color={textColor}
        _hover={{ backgroundColor: hoverBgColor }}
      >
        <Flex align="center" justify="center">
          <ListIcon iconName={provider.slug} color={textColor} boxSize="24px" />
          <Text ml="8px">Import from {provider.name}</Text>
        </Flex>
      </Button>
    );
  } else {
    return (
      <Button
        as={Link}
        to={process.env.REACT_APP_SERVER_URL + "/auth/" + provider.slug}
        backgroundColor={provider.color}
        color={textColor}
        _hover={{ backgroundColor: hoverBgColor }}
      >
        <Flex align="center" justify="center">
          <ListIcon iconName={provider.slug} color={textColor} boxSize="24px" />{" "}
          <Text ml="8px">Sign in with {provider.name}</Text>
        </Flex>
      </Button>
    );
  }
};

export default OAuthLoginButton;
