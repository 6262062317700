import { Box, HStack } from "@chakra-ui/react";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useEffect, useState } from "react";
import { UserList } from "src/types/list";

import { TALL_IMAGE_LISTS } from "src/constants";
import { UserTheme } from "src/types/userThemes";
import ListButtton from "./ListButton";

const TwoColumnListOfLists: React.FC<{
  leftCol: UserList[];
  rightCol: UserList[];
  username: string;
  theme: UserTheme;
  setLeftCol: (l: UserList[]) => void;
  setRightCol: (l: UserList[]) => void;
  isEditing: boolean;
}> = ({
  leftCol,
  rightCol,
  username,
  setLeftCol,
  setRightCol,
  theme,
  isEditing,
}) => {
  const [items, setItems] = useState<{ [key: string]: UserList[] }>({
    colA: leftCol,
    colB: rightCol,
  });

  // const [activeId, setActiveId] = useState<string | null>(null);

  useEffect(() => {
    setLeftCol(items.colA);
    setRightCol(items.colB);
  }, [items, setLeftCol, setRightCol]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // const handleDragStart = (event: any) => {
  //   const { active } = event;
  //   setActiveId(active.id);
  // };

  const handleDragOver = (event: any) => {
    const { active, over } = event;
    if (!over) return;

    const activeColumn = Object.keys(items).find((key) =>
      items[key].find((item) => item._id === active.id)
    );
    const overColumn =
      over.id === "colA" || over.id === "colB"
        ? over.id
        : Object.keys(items).find((key) =>
            items[key].find((item) => item._id === over.id)
          );

    if (!activeColumn || !overColumn || activeColumn === overColumn) {
      return;
    }

    setItems((prev) => {
      const activeItems = prev[activeColumn];
      const overItems = prev[overColumn];

      const activeIndex = activeItems.findIndex(
        (item) => item._id === active.id
      );
      const overIndex =
        over.id === "colA" || over.id === "colB"
          ? overItems.length // If dragging to an empty column, add to the end
          : overItems.findIndex((item) => item._id === over.id);

      return {
        ...prev,
        [activeColumn]: [
          ...prev[activeColumn].filter((item) => item._id !== active.id),
        ],
        [overColumn]: [
          ...prev[overColumn].slice(0, overIndex),
          activeItems[activeIndex],
          ...prev[overColumn].slice(overIndex),
        ],
      };
    });
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (!over) {
      // setActiveId(null);
      return;
    }

    if (active.id !== over.id) {
      const activeColumn = Object.keys(items).find((key) =>
        items[key].find((item) => item._id === active.id)
      );
      const overColumn =
        over.id === "colA" || over.id === "colB"
          ? over.id
          : Object.keys(items).find((key) =>
              items[key].find((item) => item._id === over.id)
            );

      if (activeColumn && overColumn) {
        setItems((prev) => {
          const activeItems = prev[activeColumn];
          const overItems = prev[overColumn];

          const activeIndex = activeItems.findIndex(
            (item) => item._id === active.id
          );
          const overIndex =
            over.id === "colA" || over.id === "colB"
              ? overItems.length // If dragging to an empty column, add to the end
              : overItems.findIndex((item) => item._id === over.id);

          if (activeColumn !== overColumn) {
            return {
              ...prev,
              [activeColumn]: [
                ...prev[activeColumn].filter((item) => item._id !== active.id),
              ],
              [overColumn]: [
                ...prev[overColumn].slice(0, overIndex),
                { ...activeItems[activeIndex] },
                ...prev[overColumn].slice(overIndex),
              ],
            };
          } else {
            return {
              ...prev,
              [overColumn]: arrayMove(overItems, activeIndex, overIndex),
            };
          }
        });
      }
    }

    // setActiveId(null);
  };

  // const activeItem =
  //   activeId &&
  //   (items.colA.find((item) => item._id === activeId) ||
  //     items.colB.find((item) => item._id === activeId));

  return (
    <Box width="100%" maxW="450px">
      <DndContext
        sensors={sensors}
        // onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <HStack spacing={3} alignItems="start">
          {/* <Column columnKey="colA" />
          <Column columnKey="colB" /> */}
          <Column
            columnKey="colA"
            items={items["colA"]}
            username={username}
            theme={theme}
            isEditing={isEditing}
          />
          <Column
            columnKey="colB"
            items={items["colB"]}
            username={username}
            theme={theme}
            isEditing={isEditing}
          />
        </HStack>
        {/* <DragOverlay>
          {activeItem && (
            <ListButtton2Col
              key={activeItem._id}
              userList={activeItem}
              username={username}
              isReorderingLists={isReorderingLists}
              marginBottom="10px"
              itemLimit={4}
              listGridColumns={2}
              itemImageHeight={
                TALL_IMAGE_LISTS.includes(activeItem.slug)
                  ? { base: "6.25em", sm: "8.5em" }
                  : { base: "4.5em", sm: "6em" }
              }
            />
          )}
        </DragOverlay> */}
      </DndContext>
    </Box>
  );
};

const Column: React.FC<{
  columnKey: string;
  items: UserList[];
  username: string;
  theme: UserTheme;
  isEditing: boolean;
}> = ({ columnKey, items, username, theme, isEditing }) => {
  const { setNodeRef } = useDroppable({ id: columnKey });
  return (
    <Box ref={setNodeRef} w="calc(50% - 0.25em)">
      <SortableContext
        items={items.map((item) => item._id ?? "")}
        strategy={verticalListSortingStrategy}
      >
        {items.map((item) => {
          const tallImages = TALL_IMAGE_LISTS.includes(item.slug);
          return (
            <ListButtton
              key={item._id}
              userList={item}
              username={username}
              marginBottom="10px"
              itemLimit={4}
              listGridColumns={2}
              itemImageHeight={
                tallImages
                  ? { base: "6.25em", sm: "8.5em" }
                  : { base: "4.5em", sm: "6em" }
              }
              theme={theme}
              isEditing={isEditing}
            />
          );
        })}
      </SortableContext>
    </Box>
  );
};

export default TwoColumnListOfLists;
